import {Inject, Injectable, LOCALE_ID} from "@angular/core";
import {Rover} from "@data/model/rover.model";
import {
  circleMarker,
  icon,
  Icon,
  LatLng,
  Layer,
  LeafletMouseEventHandlerFn,
  marker,
  polygon,
  polyline,
  tooltip
} from "leaflet";
import {OperatingStatus} from "@data/enum/operating-status.enum";
import {OrderedCoordinates} from "@data/model/ordered-coordinates.model";
import {RoverOdometrySequence} from "@data/model/rover-odometry-sequence.model";
import {RoverMarker} from "@data/model/map/rover-marker.model";
import {Parcel} from "@data/model/parcel.model";
import {ParcelPolygon} from "@data/model/map/parcel-polygon.model";
import {WaitingZone} from "@data/model/waiting-zone.model";
import {WaitingZonePolygon} from "@data/model/map/waiting-zone-polygon.model";
import {Taxiway} from "@data/model/taxiway.model";
import {TaxiwayPolyline} from "@data/model/map/taxiway-polyline.model";
import {AnchorPointMarker} from "@data/model/map/anchor-point-marker.model";
import {AnchorPoint} from "@data/model/anchor-point.model";
import {ManualTaxiway} from "@data/model/manual-taxiway.model";
import {ManualTaxiwayPolyline} from "@data/model/map/manual-taxiway-polyline.model";
import {ChargingStation} from "@data/model/charging-station.model";
import {ChargingStationMarker} from "@data/model/map/charging-station-marker.model";
import {GpsPathRecording} from "@data/model/gps-path-recording.model";
import {Crop} from "@data/model/crop.model";
import {formatDate} from "@angular/common";
import {MissionOrchestration, MissionStartingPoint} from "@data/model/mission-orchestration.model";
import {environment} from "@env/environment";

@Injectable({
  providedIn: "root"
})
export class MapService {

  roverMarkerIcon: Icon = icon({
    ...Icon.Default.prototype.options,
    iconUrl: 'assets/icons/rover-map-marker.svg',
    iconRetinaUrl: 'assets/icons/rover-map-marker.svg',
    shadowUrl: 'assets/marker-shadow.png',
    shadowSize: [0, 0],
    iconSize: [40, 40],
    iconAnchor: [20, 20],
  });

  chargingStationIconMarker: Icon = icon({
    ...Icon.Default.prototype.options,
    iconUrl: 'assets/icons/charging_station_marker_icon.svg',
    iconRetinaUrl: 'assets/icons/charging_station_marker_icon.svg',
    shadowUrl: 'assets/marker-shadow.png',
    shadowSize: [0, 0],
    iconSize: [50, 50],
    iconAnchor: [25, 25],
  });

  targetStartingPointMarker: Icon = icon({
    ...Icon.Default.prototype.options,
    iconUrl: 'assets/icons/starting_point_marker.svg',
    iconRetinaUrl: 'assets/icons/starting_point_marker.svg',
    shadowUrl: 'assets/marker-shadow.png',
    shadowSize: [0, 0],
    iconSize: [47, 64],
    iconAnchor: [23.5, 64],
  });

  chargingStationStartingPointMarker: Icon = icon({
    ...Icon.Default.prototype.options,
    iconUrl: 'assets/icons/starting_point_marker.svg',
    iconRetinaUrl: 'assets/icons/starting_point_marker.svg',
    shadowUrl: 'assets/marker-shadow.png',
    shadowSize: [0, 0],
    iconSize: [47, 64],
    iconAnchor: [23.5, 64],
  });

  anchorPointMarkerIcon: Icon = icon({
    ...Icon.Default.prototype.options,
    iconUrl: 'assets/icons/anchor-point.svg',
    iconRetinaUrl: 'assets/icons/anchor-point.svg',
    shadowUrl: 'assets/marker-shadow.png',
    shadowSize: [0, 0],
    iconSize: [20, 20],
    iconAnchor: [10, 10],
  });

  directionMarkerIcon: Icon = icon({
    ...Icon.Default.prototype.options,
    iconUrl: 'assets/icons/rover-marker-direction.svg',
    iconRetinaUrl: 'assets/icons/rover-marker-direction.svg',
    shadowUrl: 'assets/marker-shadow.png',
    shadowSize: [0, 0],
    iconSize: [145, 145],
    iconAnchor: [72.5, 72.5],
  });

  constructor(@Inject(LOCALE_ID) private locale: string) {

  }


  buildRoverMarker(rover: Rover, withYaw: boolean = true, clickFn?: LeafletMouseEventHandlerFn): any {
    if (!rover.status
      || !rover.status?.positionStatus
      || !rover.status?.positionStatus?.coordinates?.latitude
      || !rover.status?.positionStatus?.coordinates?.longitude) {
      return;
    }

    const roverPos = new LatLng(
      rover.status.positionStatus.coordinates.latitude,
      rover.status.positionStatus.coordinates.longitude,
      rover.status.positionStatus.coordinates.altitude,
    );

    let roverMarker = new RoverMarker(roverPos, rover, {
      icon: this.roverMarkerIcon,
      zIndexOffset: 999,
    })

    if (clickFn) {
      roverMarker.on("click", clickFn);
    }

    let layers: any = [
      roverMarker,
      circleMarker(roverPos, {
        radius: 22,
        color: OperatingStatus.getColor(rover.status.operatingStatus)
      })
    ];

    if (withYaw) {
      layers.unshift(marker(roverPos, {
        icon: this.directionMarkerIcon,
        rotationAngle: rover.status.positionStatus.yaw - 180,
      }));
    }

    return layers;

  }

  buildGeofence(coordinates: OrderedCoordinates[], type: string, clickFn?: LeafletMouseEventHandlerFn): Layer[] {
    coordinates.sort((a, b) => a.recordOrder - b.recordOrder);
    let polygonCoordinates = coordinates.map(c => new LatLng(c.latitude, c.longitude, c.altitude));
    let color;
    switch (type) {
      case 'parcel':
        color = '#FB8826';
        break;

      case 'hdz':
        color = '#fbb826';
        break;

      case 'waiting-zone':
        color = '#2649fb';
        break;
    }
    return [
      polygon(polygonCoordinates, {
        color: color,
        fill: true,
        weight: 3
      })
    ];
  }

  buildParcel(parcel: Parcel, clickFn?: LeafletMouseEventHandlerFn): ParcelPolygon {
    parcel.geofence.sort((a, b) => a.recordOrder - b.recordOrder);
    let polygonCoordinates = parcel.geofence.map(c => new LatLng(c.latitude, c.longitude, c.altitude));

    let polygon: ParcelPolygon;

    if(parcel.id){
      polygon = new ParcelPolygon(
        parcel,
        polygonCoordinates,
        {
          color: '#FB8826',
          fill: true,
          weight: 3
        }
      );
    } else {
      polygon = new ParcelPolygon(
        parcel,
        polygonCoordinates,
        {
          color: '#fb262f',
          fill: true,
          weight: 3
        }
      );
    }


    if (clickFn) {
      polygon.on("click", clickFn);
    }

    return polygon;
  }

  buildParcelTooltip(polygon: ParcelPolygon, parcel: Parcel, crop: Crop) {
    let cropStatus = crop?.statusHistory[0];
    let t = tooltip({
      permanent: true,
      className: "parcel-tooltip",
      direction: 'center',
    }).setContent(parcel.name
      + "</br>" + parcel.areaInSquareMeters + " m²"
      + (crop ? ("</br>" + crop?.type.name) : "")
      + (crop ? (" : " + crop?.seedingDate) : "")
      + (cropStatus?.medianStage ? ("</br>" + cropStatus?.medianStage) : "")
      + (cropStatus?.timestamp ? (" : " + formatDate(cropStatus?.timestamp, 'short', this.locale)) : ""));

    polygon.bindTooltip(t).openTooltip();
  }

  buildWaitingZone(waitingZone: WaitingZone, clickFn?: LeafletMouseEventHandlerFn): WaitingZonePolygon[] {
    waitingZone.geofence.sort((a, b) => a.recordOrder - b.recordOrder);
    let polygonCoordinates = waitingZone.geofence.map(c => new LatLng(c.latitude, c.longitude, c.altitude));
    let polygon = new WaitingZonePolygon(
      waitingZone,
      polygonCoordinates,
      {
        color: '#2649fb',
        fill: true,
        weight: 3
      }
    );

    if (clickFn) {
      polygon.on("click", clickFn);
    }

    return [polygon];
  }

  buildTaxiway(taxiway: Taxiway, clickFn?: LeafletMouseEventHandlerFn): TaxiwayPolyline[] {
    taxiway.itinerary.sort((a, b) => a.recordOrder - b.recordOrder);
    let polylineCoordinates = taxiway.itinerary.map(c => new LatLng(c.latitude, c.longitude, c.altitude));
    let polyline = new TaxiwayPolyline(
      taxiway,
      polylineCoordinates,
      {
        color: '#269bfb',
        weight: 3
      }
    );

    if (clickFn) {
      polyline.on("click", clickFn);
    }

    return [polyline];
  }

  buildManualTaxiway(manualTaxiway: ManualTaxiway, clickFn?: LeafletMouseEventHandlerFn): ManualTaxiwayPolyline[] {
    manualTaxiway.itinerary.sort((a, b) => a.recordOrder - b.recordOrder);
    let polylineCoordinates = manualTaxiway.itinerary.map(c => new LatLng(c.latitude, c.longitude, c.altitude));
    let polyline = new ManualTaxiwayPolyline(
      manualTaxiway,
      polylineCoordinates,
      {
        color: '#5bfb26',
        weight: 3
      }
    );

    if (clickFn) {
      polyline.on("click", clickFn);
    }

    return [polyline];
  }

  buildGpsPathRecording(recording: GpsPathRecording): Layer[] {
    return recording.segments.flatMap(segment => {
      return this.buildItinerary(segment.path, segment.type.toLowerCase() === "lane" ? '#67e300' : '#2662fb')
    });
  }

  buildItinerary(coordinates: OrderedCoordinates[], color?: string): Layer[] {
    coordinates.sort((a, b) => a.recordOrder - b.recordOrder);
    let polylineCoordinates = coordinates.map(c => new LatLng(c.latitude, c.longitude, c.altitude));
    return [
      polyline(polylineCoordinates, {
        color: color,
        weight: 3
      })
    ];
  }

  buildAnchorPoint(anchorPoint: AnchorPoint, clickFn?: LeafletMouseEventHandlerFn): AnchorPointMarker[] {

    const anchorPointPos = new LatLng(
      anchorPoint.coordinates.latitude,
      anchorPoint.coordinates.longitude,
      anchorPoint.coordinates.altitude,
    );

    let anchorPointMarker = new AnchorPointMarker(anchorPointPos, anchorPoint, {
      icon: this.anchorPointMarkerIcon,
    })

    if (clickFn) {
      anchorPointMarker.on("click", clickFn);
    }

    if (!environment.production) {
      let t = tooltip({
        permanent: true,
        className: "ac-debug-tooltip",
        direction: 'center',
      }).setContent(anchorPoint.id);

      anchorPointMarker.bindTooltip(t).openTooltip();
    }

    return [anchorPointMarker];

  }

  // buildRoverOdometrySequence(sequence: RoverOdometrySequence): Layer[] {
  //
  //   if (!sequence || !sequence.entries) return [];
  //
  //   let coordinates = sequence.entries
  //     .filter(entry => entry.positionStatus?.coordinates?.latitude && entry.positionStatus?.coordinates?.longitude)
  //     .map(entry => {
  //       return new LatLng(entry.positionStatus.coordinates.latitude, entry.positionStatus.coordinates.longitude, entry.positionStatus.coordinates.altitude)
  //     });
  //   return [
  //     polyline(coordinates, {
  //       color: OperatingStatus.getColor(sequence.entries[0]?.operatingStatus),
  //       weight: 2
  //     })
  //   ];
  // }

  buildRoverOdometrySequence(sequence: RoverOdometrySequence): Layer[] {
    if (!sequence || !sequence.entries) return [];

    const layers: Layer[] = [];
    let currentOperatingStatus: any = null;
    let currentCoordinates: LatLng[] = [];

    sequence.entries.forEach(entry => {
      if (entry.positionStatus?.coordinates?.latitude && entry.positionStatus?.coordinates?.longitude) {
        const coordinates = new LatLng(
          entry.positionStatus.coordinates.latitude,
          entry.positionStatus.coordinates.longitude,
          entry.positionStatus.coordinates.altitude
        );
        const operatingStatus = entry.operatingStatus;

        if (currentOperatingStatus !== operatingStatus) {
          if (currentCoordinates.length > 0) {
            layers.push(polyline(currentCoordinates, {
              color: OperatingStatus.getColor(currentOperatingStatus),
              weight: 2
            }));
          }
          currentOperatingStatus = operatingStatus;
          currentCoordinates = [coordinates];
        } else {
          currentCoordinates.push(coordinates);
        }
      }
    });

    if (currentCoordinates.length > 0) {
      layers.push(polyline(currentCoordinates, {
        color: OperatingStatus.getColor(currentOperatingStatus),
        weight: 2
      }));
    }

    return layers;
  }

  buildChargingStation(chargingStation: ChargingStation, clickFn?: LeafletMouseEventHandlerFn, withYaw: boolean = true): any {
    if (!chargingStation.coordinates
      || !chargingStation.coordinates.latitude
      || !chargingStation.coordinates.longitude) {
      return;
    }

    const chargingStationPos = new LatLng(
      chargingStation.coordinates.latitude,
      chargingStation.coordinates.longitude,
      chargingStation.coordinates.altitude,
    );

    let chargingStationMarker = new ChargingStationMarker(chargingStationPos, chargingStation, {
      icon: this.chargingStationIconMarker,
    })

    if (clickFn) {
      chargingStationMarker.on("click", clickFn);
    }

    let layers: any = [
      chargingStationMarker,
      // circleMarker(chargingStationPos, {
      //   radius: 22,
      //   color: '#FB8E31'
      // })
    ];

    // if (withYaw) {
    //   layers.unshift(marker(chargingStationPos, {
    //     icon: this.directionMarkerIcon,
    //     rotationAngle: chargingStation.yaw,
    //   }));
    // }

    return layers;

  }

  buildMissionStartingPointMarker(map: L.Map, startingPoint: MissionStartingPoint) {
    if (!startingPoint
      || !startingPoint?.coordinates?.latitude
      || !startingPoint?.coordinates?.longitude) {
      return;
    }
    map.addLayer(marker(new LatLng(startingPoint?.coordinates?.latitude, startingPoint?.coordinates?.longitude), {
      icon: this.targetStartingPointMarker,
      zIndexOffset: 1000,
    }));
  }

  unlinkedLayerDisplay(layer: TaxiwayPolyline | ManualTaxiwayPolyline | ParcelPolygon | WaitingZonePolygon){
    layer.setStyle({ opacity: 0.3 });
    if(layer instanceof ParcelPolygon || WaitingZonePolygon){
      layer.setStyle({ fillOpacity: 0.1 });
    }
  }

  notFullyLinkedPolyline(layers: TaxiwayPolyline[] | ManualTaxiwayPolyline[]){
    layers.forEach(polyline => {
      polyline.options.dashArray="10,10";
    });
  }

  unlinkedAnchorPointMarkersDisplay(marker: AnchorPointMarker[], unlinkedAnchorPoints: AnchorPoint[]){
    unlinkedAnchorPoints.forEach(unlinkedAnchorPoint => {
      marker.forEach(anchorPointMarker => {
        if (
          anchorPointMarker.anchorPoint.coordinates.latitude === unlinkedAnchorPoint.coordinates.latitude &&
          anchorPointMarker.anchorPoint.coordinates.longitude === unlinkedAnchorPoint.coordinates.longitude
        ) {
          anchorPointMarker.setOpacity(0.5);
        }
      });
    });
  }

  unlinkedMarkersDisplay(marker: ChargingStationMarker | AnchorPointMarker){
    marker.setOpacity(0.2);
  }

  linkedMarkersDisplay(marker: ChargingStationMarker | AnchorPointMarker){
    marker.setOpacity(1);
  }

  resetAllLayersStyle(taxiwayLayers: TaxiwayPolyline[], manualTaxiwayLayers: ManualTaxiwayPolyline[], parcelLayers: ParcelPolygon[], waitingZoneLayers: WaitingZonePolygon[], chargingStationMarkers: ChargingStationMarker[]): void {
    taxiwayLayers.forEach(taxiwayLayer => {
      taxiwayLayer.setStyle({ opacity: 1  });
    });

    parcelLayers.forEach(parcelLayer => {
      parcelLayer.setStyle({ opacity: 1, fillOpacity: 0.5});
    })

    waitingZoneLayers.forEach(waitingZoneLayer => {
      waitingZoneLayer.setStyle({ opacity: 1, fillOpacity: 0.5});
    });

    manualTaxiwayLayers.forEach(manualTaxiwayLayer => {
      manualTaxiwayLayer.setStyle({opacity: 1 });
    });

    chargingStationMarkers.forEach(chargingStationMarker => {
      this.linkedMarkersDisplay(chargingStationMarker)
    })
  }
}
